import { useSearchParams } from 'react-router';

export function useGetCartSearchParams() {
  const [searchParams] = useSearchParams();
  const cartGuid = searchParams.get('cartGuid') || '';
  const productsAdded = searchParams.get('productsAdded') === 'true' || '';
  const cartRowGuids = searchParams.get('cartRowGuids')?.split(',') || [];

  return {
    cartGuid,
    cartRowGuids,
    productsAdded,
  };
}
