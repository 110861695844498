import { createBrowserRouter, RouterProvider } from 'react-router';
import * as Sentry from '@sentry/react';
import { routes } from './routes';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

function App() {
  const router = sentryCreateBrowserRouter(routes);
  return <RouterProvider router={router} />;
}

export default App;
