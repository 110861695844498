import { Path, useLocation } from 'react-router';
import { NavigateOptions } from '../../types';

/**
 * Custom hook for handling search parameters in the URL.
 * @returns  Function to get updated search parameters.
 */
export function useSearchParamHandler(): (
  nextSearchParams: Path['search'] | undefined,
  options: NavigateOptions,
) => string | undefined {
  const { search } = useLocation();

  /**
   * Get updated search parameters based on the provided options.
   * @param nextSearchParams - The new search parameters to be added.
   * @param options - Navigation options.
   * @param options.keepSearchParams - Whether to keep existing search parameters.
   * @param options.skipSearchParam - Search parameters to remove.
   * @returns Updated search parameters string.
   */
  function getUpdatedSearchParams(
    nextSearchParams: string | undefined,
    { keepSearchParams, skipSearchParam }: NavigateOptions,
  ): string | undefined {
    const currentParams = new URLSearchParams(search);

    if (skipSearchParam) {
      skipSearchParam.forEach(key => currentParams.delete(key));
    }

    const currentStringParams = currentParams.toString();
    if (keepSearchParams && currentStringParams) {
      return nextSearchParams ? `${currentStringParams}&${nextSearchParams}` : currentStringParams;
    }

    return nextSearchParams;
  }

  return getUpdatedSearchParams;
}
