import { FooterPayments, Header } from '@photosi/web-design-system';
import { Navigate } from '../../../_shared/components';
import { useGetUserType } from '../../../_shared/hooks';
import { PATHS } from '../../../paths';
import { Outlet } from 'react-router';
import { ConfidentialPriceProvider } from '..';
import { PHOTOSI_URL } from '../../../settings';

export const CartRetailerLayout: React.FC = () => {
  const { isRetailerUser } = useGetUserType();

  if (!isRetailerUser) {
    return <Navigate to={{ pathname: `/${PATHS.SHOP}` }} keepSearchParams />;
  }

  return (
    <ConfidentialPriceProvider>
      <Header data-testid="header" isBusiness urlLogo={PHOTOSI_URL} />
      <div className="pds-flex pds-justify-center pds-min-h-[73vh]">
        <div className="pds-mt-10 pds-px-6 pds-mb-3 pds-w-full pds-h-full pds-max-w-screen-2xl md:pds-px-7 lg:pds-px-16">
          <Outlet />
        </div>
      </div>
      <FooterPayments data-testid="footer" />
    </ConfidentialPriceProvider>
  );
};
