import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { initI18n } from './_shared/i18n';
import { BRAZE_API_KEY, BRAZE_BASE_URL, BRAZE_SERVICEWORKER_LOCATION, GTM_ID, SENTRY_DSN } from './settings';
import App from './App';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router';
import './index.css';
import { caseProcessor } from './_shared/i18n/formatter/case-processor';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';
import * as braze from '@braze/web-sdk';
import { setRetryOptions } from './_shared/utils';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0,
  normalizeDepth: 10,
  ignoreErrors: ['Failed to subscribe the user'],
});

TagManager.initialize({
  gtmId: GTM_ID,
});

braze.initialize(BRAZE_API_KEY, {
  baseUrl: BRAZE_BASE_URL,
  serviceWorkerLocation: BRAZE_SERVICEWORKER_LOCATION,
  manageServiceWorkerExternally: true,
});

braze.requestPushPermission();
braze.openSession();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: setRetryOptions,
    },
    mutations: {
      retry: setRetryOptions,
    },
  },
});
i18n.use(initReactI18next).use(caseProcessor).init(initI18n);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>Ops!</p>}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools client={queryClient} />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
