import { useEffect } from 'react';
import { goToLoginPage } from '../../utils/goToLoginPage';
import { Loader } from '@photosi/web-design-system';
import { useLocation } from 'react-router';

export const Login: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const next: string = queryParams.get('next') || '';

  useEffect(() => {
    goToLoginPage(next);
  }, [next]);

  return <Loader />;
};
