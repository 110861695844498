import { ofetch } from 'ofetch';
import { API_ROOT, API_SOFTWARE_CODE, API_SOFTWARE_VERSION, X_API_KEY } from '../../settings';
import { getLanguage } from '../utils';
import { getTokenFromParamsOrCookie } from '../utils';

const defaultHeaders = {
  'x-software-code': API_SOFTWARE_CODE,
  'x-software-version': API_SOFTWARE_VERSION,
  'accept-language': getLanguage(),
  'x-api-key': `${X_API_KEY}`,
};

export const api = ofetch.create({
  baseURL: API_ROOT,
  onRequest: ({ options }) => {
    const token = getTokenFromParamsOrCookie();

    if (token) {
      const headers = new Headers({ ...defaultHeaders, ...options.headers, authorization: `Bearer ${token}` });

      options.headers = headers;
    }
  },
});
